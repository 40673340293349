import React from 'react';
import Header from './header';
import Footer from './footer';
import { Icon } from '@iconify/react/dist/iconify.js';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';



function About() {
    return (

        <div className="min-h-screen">

            <div className="md:flex md:justify-center">

                {/* Top message with importance of process*/}
                <div class="md:flex md:w-3/4 md:justify-center w-full h-full ">
                    <div class="md:w-1/2 w-full h-full md:mt-16  p-4 md:flex md:justify-end ">
                        <img src="./images/knowmore7.jpg" alt="About Us" className="mt-2 w-full h-auto object-cover rounded-2xl" />
                    </div>
                    <div class="md:w-1/2 w-full h-full md:mt-32 p-4">
                        <h1 className="text-4xl font-bold">Empowering Your Business in Singapore: A Partnership You Can Trust</h1>
                        <div className="">

                            <p className="mt-4 text-lg"> Zen Corp is a leading provider of incorporation, accounting, and tax services in Singapore. We are committed to delivering exceptional services to businesses of all sizes, from startups to established corporations.</p>
                          
                            <a aria-label="Chat on WhatsApp" class="w-full md:float-start md:mr-5 md:mt-10" href="https://wa.me/6590103503"> <img alt="Chat on WhatsApp" src="./images/WhatsAppButtonGreenLarge.svg" />
                            </a>
                            <Link to="/contact" className="md:justify-start md:mt-10 w-full ">
                                <button className="bg-green-500 mt-5 w-52 text-white py-3 rounded-lg font-extrabold ">
                                   
                                    Other Contacts!
                                </button>
                            </Link>

                        </div>

                    </div>


                </div>
            </div>
            <h2 className="text-3xl font-bold text-center mb-2">Our Values</h2>
            <div className="md:flex md:justify-center">

                {/* Top message with importance of process*/}
                <div class="md:flex md:w-3/4 md:justify-center w-full h-full ">

                    <div className="md:grid md:grid-cols-3 grid grid-cols-1  px-4 ">


                        <div>
                            <h3 className="text-xl font-semibold px-4">Integrity</h3>
                            <p className="text-gray-700 mt-2 text-sans font-medium text-left px-4"> We are committed to conducting business with honesty, transparency, and ethical principles.

                                We believe that trust is earned, not given. We strive to maintain the highest standards of professionalism, ensuring that every interaction is marked by fairness and reliability. Our commitment to integrity extends to every aspect of our operations, from the quality of our services to the way we treat our clients.</p>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold px-4 ">Expertise</h3>
                            <p className="text-gray-700 mt-2 text-sans font-medium text-left px-4">Our team of seasoned professionals is well-versed in the latest regulations and industry best practices. We specialize in providing comprehensive corporate solutions, including company incorporation, corporate secretarial services, tax advisory, and accounting services.</p>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold px-4">Client-Centric Approach</h3>
                            <p className="text-gray-700 mt-2 text-sans font-medium text-left px-4">We believe in building strong, long-lasting relationships with our clients by providing exceptional service and support. Our dedicated team of professionals is committed to understanding your unique business requirements and tailoring our services to meet your specific needs. We strive to exceed your expectations by delivering timely, accurate, and reliable solutions.</p>
                        </div>
                    </div>
                </div>
            </div>






        </div>








    );
};

export default About;