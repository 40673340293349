import React, { useContext,useState,onNavigate, List } from 'react';
import { Link } from 'react-router-dom';
import { Popover, PopoverButton, PopoverPanel, PopoverGroup } from '@headlessui/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { ListItem } from '@material-tailwind/react';
import { Menu, X } from 'lucide-react';




const products = [
    {
        name: 'Incorporation Services', description: 'Setting up business in Singapore', link: '/incorporation',
        icon: 'bxs:business', color: 'green'
    },
    { name: ' Secretarial Services', description: 'From Idea to Incorporation, Simplified', link: '/secretarial', icon: 'mingcute:user-security-fill', color: "blue" },
    { name: 'Accounting Services', description: 'The Key to Your Singapore Enterprise', link: '/accounting', icon: 'pajamas:license', color: "purple" },
    { name: 'Taxation Services', description: 'Comprehensive GST Solutions', link: '/tax', icon: 'tabler:tax', color: "red" },
    // { name: 'Payroll Processing', description: 'Effortless Payroll', link: '/payroll', icon: 'tdesign:money', color: "orange" },
    { name: 'More About Us', description: 'Effortless Payroll', link: '/about', icon: 'fxemoji:girlgirl', color: "red" },
    { name: 'Contact Us', description: 'Contact us', link: '/contact', icon: 'line-md:phone-call-twotone-loop', color: "red" },
];
export default function Header({ onNavigate = () => {} }) {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
      };
      const handleClick = (path) => {
        onNavigate(path);
        setIsOpen(false);
      };
    return (


        <header className="fixed w-screen">
            <nav class="bg-white shadow-lg Z-20 border-gray-200 py-2.5">
                <div class="flex flex-auto items-center justify-between max-w-screen-xl px-4 mx-auto">
                    <a href="/" class="flex items-center">
                        <img src="./images/logo.png" class="h-6 mr-3 sm:h-9" alt="Zen Corp Logo" />
                        <span class="self-center text-xl font-extrabold font-google-sans whitespace-nowrap ">Zen Corp</span>
                    </a>
                    <div class="flex items-center lg:order-2">


                        {/* <button data-collapse-toggle="mobile-menu-2" type="button" class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
                                    <span class="sr-only">Open main menu</span>
                                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                                    <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </button> */}
                        <button
                            onClick={toggleMenu}
                            className="p-2 text-gray-600 hover:text-gray-800 focus:outline-none block md:hidden"
                            aria-label="Toggle menu"
                        >
                            {isOpen ? (
                                <X className="h-6 w-6" />
                            ) : (
                                <Menu className="h-6 w-6" />
                            )}
                        </button>
                        {isOpen && (
                            <div className="absolute right-5 mt-48 w-48 bg-white rounded-md shadow-lg py-1 z-50">
                                <nav>
                                    {products.map((item, index) => (
                                        <Link
                                            key={index}
                                            to={item.link}
                                            className="block  px-4 py-2 text-sm  font-semibold text-gray-700 hover:bg-gray-100 transition-colors duration-150"
                                            onClick={() => handleClick(item.link)}
                                        >
                                            {item.name}
                                        </Link>
                                    ))}
                                </nav>
                            </div>
                        )}
                    </div>
                    <div class="items-center justify-between hidden w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
                        <ul class="flex flex-col mt-2 font-medium lg:flex-row lg:space-x-1 lg:mt-0">
                            {
                                products.map((product, index) => (
                                    <li key={index} class="">
                                        <Link to={product.link} className="flex text-sm text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0   ">
                                            <Icon icon={product.icon} color={product.color} className={`w-1/6 h-full mt-1`} />
                                            <span className='flex-grow text-wrap font-bold ml-2'>{product.name}</span>
                                        </Link>
                                    </li>
                                ))
                            }


                            {/* <li>
                                        <a href="/incorporation" class="block py-2 pl-3 pr-4 text-white bg-purple-700 rounded lg:bg-transparent lg:text-purple-700 lg:p-0 dark:text-white" aria-current="page">Incorporation</a>
                                    </li>
                                    <li>
                                        <a href="/accounting" class="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Accounting</a>
                                    </li>
                                    <li>
                                        <a href="/secretarial" class="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Secratarial</a>
                                    </li>
                                    <li>
                                        <a href="/tax" class="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Tax</a>
                                    </li>
                                    <li>
                                        <a href="/licenses" class="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Licenses</a>
                                    </li>
                                    <li>
                                        <a href="/about" class="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">About Us</a>
                                    </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </header >




    );
};

