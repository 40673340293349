import React, { useState } from 'react';
import Header from './header';
import Footer from './footer';
import { Icon } from '@iconify/react/dist/iconify.js';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Loader2 } from 'lucide-react';
import useHttpService from './useHttpService';


function Tax() {
    const { isLoading, error, post } = useHttpService();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        tel: '',
        country: ''
    });
    const [isFormValid, setIsFormValid] = useState({ valid: formData.name != '' && formData.email != '' && formData.tel != '' && formData.country != '' });

    const [submitStatus, setSubmitStatus] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setIsFormValid({ valid: formData.name != '' && formData.email != '' && formData.tel != '' && formData.country != '' });
        console.log(isFormValid);
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitStatus('');

        try {
            const response = await post('services.php', {
                action: 'submit_form',
                ...formData
            });

            setSubmitStatus('success');
            setFormData({ name: '', email: '', message: '', tel: '', country: '' }); // Reset form
            console.log('Form submitted successfully:', response);
        } catch (err) {
            setSubmitStatus('error');
            console.error('Form submission failed:', err);
        }
    };
    return (


        <div className="min-h-screen">

            {/* Top message with importance of process*/}
            <div class="md:flex w-full h-full">
                <div class="md:w-1/2 w-full h-full md:mt-16 p-4">
                    <span className="flex" >
                        <Breadcrumbs aria-label="breadcrumb" className="float-start">
                            <Link underline="hover" color="inherit" href="/" className="text-lg font-bold">
                                Home
                            </Link>

                            <Link
                                underline="hover"
                                color="text.primary"

                                aria-current="page"
                            >
                                Taxation Services
                            </Link>
                        </Breadcrumbs>

                    </span>


                    <div class="flex md:mt-5 mt-2">
                    <span className="md:w-1/12 w-12 mt-4 p-4 mr-4">
                            <Icon icon="hugeicons:taxes" color="red" class="w-12 h-24" />
                        </span>

                        <p className="flex-grow underline text-2xl font-bold mt-4 ml-2  ">
                            Navigating Taxes, Boosting Growth: Tailored Tax Services for Singapore Companies</p>



                    </div>


                    <span className="text-lg text-wrap  ">
                        <p className="p-2">
                        Tax compliance and optimization are essential to sustainable growth, and our experienced team of tax professionals is dedicated to helping you navigate Singapore’s regulatory landscape with ease. From corporate tax planning to GST and withholding tax management, we handle every aspect of your tax needs with precision and attention to detail. Whether you’re a startup, SME, or multinational enterprise, our goal is to simplify tax compliance and maximize your tax efficiency, allowing you to focus on strategic growth with confidence.

                        Our suite of tax services covers all facets of tax management, ensuring that your business remains compliant, while our tailored advisory services help you identify savings opportunities within legal boundaries. At Zen Corp, we combine our deep understanding of local tax laws with proactive planning strategies to protect your business from potential risks and optimize its tax position. Working with us means gaining a partner committed to delivering results, saving you time, and ultimately enhancing your bottom line.</p>
                        <br />  
                        <span className="text-lg font-bold p-2">Why Choose Our Tax Services?</span>

                        <ul className="list-disc  ml-12">
                            <li className='font-semibold'>
                                In-Depth Knowledge of Singapore Tax Law <br />
                                <p class="font-normal  text-gray-800"> Navigating Singapore’s complex tax regulations requires precision and expertise. Our team has an in-depth understanding of local tax laws and is constantly updated on regulatory changes. From corporate tax obligations to GST filings, we keep your business fully compliant, reducing risk and ensuring you are well-prepared for any audit or tax review.</p>
                            </li>
                            <li className='font-semibold'>
                                Customized Tax Solutions for Optimal Savings<br />
                                <p class="font-normal  text-gray-800"> Every business is unique, and we understand the importance of a tax strategy that aligns with your specific goals. Our tax experts provide customized tax planning services, identifying opportunities for deductions, exemptions, and reliefs to optimize your tax position. With a focus on long-term efficiency, we help you retain more of your earnings while staying within regulatory boundaries.</p>
                            </li>
                            <li className='font-semibold'>
                                Full-Spectrum Tax Services for Total Peace of Mind<br />
                                <p class="font-normal  text-gray-800"> From routine tax filings to strategic advisory, our tax services cover every aspect of tax management. With [Your Firm Name], you can expect timely, accurate, and comprehensive support for all tax matters, allowing you to simplify compliance and achieve peace of mind.</p>
                            </li>


                        </ul>

                    </span>
                </div>
                <div class="md:w-1/2 md:block w-full h-full md:mt-32 p-4 hidden">
                    <img src="/images/officemain.jpg" alt="Company Vision"
                        class="object-cover  mt-32    shadow-2xl border-4  w-full h-1/4 overflow-hidden rounded-xl" />
                </div>
            </div>
            <div class="md:flex w-full h-full">
                <div class="md:w-1/2 md:block w-full h-full md:mt-16 p-4 hidden">
                    <img src="/images/company_mission.jpeg" alt="Company Vision"
                        class="object-cover mt-5     shadow-2xl border-1  w-full overflow-hidden rounded-xl" />


                </div>
                <div class="md:w-1/2 w-full h-full md:mt-0 p-4">
                    <div className="mt-24 text-center">
                        <h1 class="text-slate-900 font-extrabold text-4xl sm:text-5xl lg:text-6xl tracking-tight text-center ">
                            Fuel Your Business Growth, Tax-Efficient!
                        </h1>
                        <p className='p-4 text-left'>This service offers expert tax advice and strategic planning to help businesses minimize their tax burden and maximize their financial potential. By handling all tax compliance needs, including GST filings and tax returns, the service allows businesses to focus on growth and innovation.Our proactive approach includes timely alerts and reminders to keep you informed of tax changes and potential risks. By partnering with us, you can focus on your core business activities, knowing that your tax affairs are in capable hands.
                        </p>
                        <ul className="list-disc text-left ml-10 pr-4">
                            <li>

                                <b>Strategic Tax Planning:</b> We'll help you minimize your tax burden and optimize your financial strategy.
                            </li>
                            <li>

                                <b>Expert Tax Advice:</b>  Our team of tax experts provides tailored advice to meet your specific needs.
                            </li>
                            <li>

                                <b>Hassle-Free Tax Compliance:</b> Our team will proactively identify potential financial risks and opportunities and provide timely advice.
                            </li>
                        </ul>


                    </div>
                </div>
            </div>
            <div class="md:flex w-full h-full">
                <div class="md:w-1/2 w-full h-full md:mt-0 p-4 ">
                    <div class="grid mt-5 flex-fit">
                       
                        <h1 class="text-slate-900 font-extrabold ml-4 mb-2 text-4xl sm:text-5xl lg:text-6xl tracking-tight  ">
                        Our Tax Services
                        </h1>

                        <ul className="list-disc  ml-12 text-lg text-wrap">
                            <li className='font-semibold'>
                                Corporate Tax Compliance & Filing<br />
                                <p class="font-normal  text-gray-800"> Stay compliant with Singapore’s corporate tax requirements effortlessly. We handle all aspects of your tax filing, from preparing accurate financial statements to submitting timely returns to IRAS. Our team ensures that all forms and reports meet regulatory standards, so you avoid penalties and focus on core business activities.</p>
                            </li>
                            <li className='font-semibold'>
                                GST Registration & Filing<br />
                                <p class="font-normal  text-gray-800"> If your business is subject to GST, we manage everything from GST registration to quarterly filings. Our team ensures full compliance with GST regulations, while exploring potential rebates or offsets. With our expertise, you avoid common filing errors and stay aligned with Singapore’s GST guidelines.</p>
                            </li>
                            <li className='font-semibold'>
                                Tax Planning & Advisory<br />
                                <p class="font-normal  text-gray-800"> Effective tax planning is crucial for business growth and sustainability. Our tax planning services help you strategically structure your finances to reduce tax liabilities legally. Our advisory team identifies tax-efficient solutions tailored to your industry and business model, providing insights that support long-term profitability.</p>
                            </li>
                            <li className='font-semibold'>
                                International Taxation & Transfer Pricing<br />
                                <p class="font-normal  text-gray-800"> For businesses with cross-border transactions, our international tax specialists provide guidance on transfer pricing, tax treaties, and cross-border compliance. We help you navigate complex international tax rules, ensuring your global operations remain tax-efficient and compliant with Singapore’s standards.</p>
                            </li>
                            <li className='font-semibold'>
                                Withholding Tax Advisory<br />
                                <p class="font-normal  text-gray-800"> If your business makes payments to non-residents, we offer specialized withholding tax services. Our experts help you calculate, file, and comply with withholding tax requirements, reducing risk and avoiding potential penalties related to overseas transactions.</p>
                            </li>
                        </ul>
                    </div>

                </div>
                <div class="md:w-1/2 md:block w-full h-full md:mt-28 p-4 m-4 hidden">
                    <div class="flex justify-center mb-4  ">
                        <img src="/images/phoneinhand.jpg" alt="Company Vision"
                          class="object-cover mt-5     shadow-2xl border-1  w-full overflow-hidden rounded-xl" />

                    </div>
                </div>
            </div>
            <div class="md:flex w-full h-full">
                <div class="md:w-1/2 w-full h-full md:mt-0 p-4">
                    <figure class="relative md:mt-20 max-w-2xl    transition-all float-right duration-300 cursor-pointer filter  hover:grayscale-0">

                        <img src="/images/knowmore5.jpg" alt="Company Vision"
                            class="object-cover w-3/4 justify-center overflow-hidden mt-10 ml-16 rounded-2xl shadow-2xl" />
                        <figcaption class="absolute px-4 text-md text-black md:top-10 md:left-36 mt-2 top-10 left-16">
                            <p className='text-lg font-bold text-center text-white'>Let me help you with taxes </p>
                        </figcaption>
                    </figure>


                </div>
                <div class="md:w-1/2 w-full h-full md:mt-16 p-4">
                    <div class="mt-3 text-lg p-4">


                        <form onSubmit={handleSubmit}>
                            <span className='pt-10 text-2xl font-google-sans font-extrabold '>The Benefits of Outsourcing Your Tax Needs</span>
                            <span className="text-md font-normal text-wrap  ">
                                <br />Outsourcing tax functions allows you to access expertise, mitigate risks, and save time. By partnering with Zen Corp, you gain a reliable team dedicated to keeping your business tax-compliant while focusing on cost savings. Our services are scalable, supporting both your immediate and long-term tax requirements as your business grows.</span>
                            <div class="grid gap-6 mb-6 md:grid-cols-2 mt-4 p-2">

                                <div>
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 ">Your  name</label>
                                    <input type="text" value={formData.name} name="name"
                                        onChange={handleChange}
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Your Name" required />
                                </div>


                                <div>
                                    <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 ">Phone number</label>
                                    <input type="tel" value={formData.tel} name="tel"
                                        onChange={handleChange}
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="123-45-678"  required />
                                </div>
                                <div>
                                    <label for="website" class="block mb-2 text-sm font-medium text-gray-900 ">Email</label>
                                    <input type="text" value={formData.email} name="email"
                                        onChange={handleChange}
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="john.doe@company.com" required />
                                </div>
                                <div>
                                    <label for="country" class="block mb-2 text-sm font-medium text-gray-900 ">Country of Origin</label>
                                    <input type="text" value={formData.country} name="country"
                                        onChange={handleChange}
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="United States" required />

                                </div>

                            </div>
                            <div class="mb-6 p-4">
                                <div>
                                    <label for="comments" class="block mb-2 text-sm font-medium text-gray-900 ">Any comments</label>
                                    <textarea value={formData.message} name="message"
                                        onChange={handleChange}
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="I need to setup company in Singapore" />
                                </div>
                            </div>

                            <button disabled={!isFormValid.valid} type="submit" class="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center ">Submit</button>
                            {/* Status Messages */}

                            {submitStatus === 'success' && (
                                <div className="p-3 bg-green-50 border border-green-200 text-green-600 rounded">
                                    Message sent successfully!
                                </div>
                            )}

                            {submitStatus === 'error' && (
                                <div className="p-3 bg-red-50 border border-red-200 text-red-600 rounded">
                                    {error || 'Failed to send message. Please try again.'}
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>







        </div >



    );
};

export default Tax;