import React, { useState } from 'react';
import Header from './header';
import Footer from './footer';
import { Icon } from '@iconify/react/dist/iconify.js';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Loader2 } from 'lucide-react';
import useHttpService from './useHttpService';


function Incorporation() {
    const { isLoading, error, post } = useHttpService();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        tel: '',
        country: ''
    });
    const [isFormValid, setIsFormValid] = useState({ valid: formData.name != '' && formData.email != '' && formData.tel != '' && formData.country != '' });

    const [submitStatus, setSubmitStatus] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setIsFormValid({ valid: formData.name != '' && formData.email != '' && formData.tel != '' && formData.country != '' });
        console.log(isFormValid);
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitStatus('');

        try {
            const response = await post('services.php', {
                action: 'submit_form',
                ...formData
            });

            setSubmitStatus('success');
            setFormData({ name: '', email: '', message: '', tel: '', country: '' }); // Reset form
            console.log('Form submitted successfully:', response);
        } catch (err) {
            setSubmitStatus('error');
            console.error('Form submission failed:', err);
        }
    };
    return (

        <div className="min-h-screen">

            {/* Top message with importance of process*/}
            <div class="md:flex w-full h-full">
                <div class="md:w-1/2 w-full h-full md:mt-16 p-4">

                    <span className="w-full h-full " >
                        <Breadcrumbs aria-label="breadcrumb" className="float-start mt-10">
                            <Link underline="hover" color="inherit" href="/" className="text-lg font-bold">
                                Home
                            </Link>

                            <Link
                                underline="hover"
                                color="text.primary"

                                aria-current="page"
                            >
                                Incorporation Services
                            </Link>
                        </Breadcrumbs>


                    </span>



                    <div class="p-4 mt-10">


                        <p className="flex-grow underline text-2xl font-bold mt-2  ">
                            Why Setup Business In Singapore?</p>



                    </div>


                    <span className="text-lg text-wrap">
                        <p class='p-4'>
                            Singapore is globally recognized for its pro-business environment, efficient regulatory framework, and supportive infrastructure, making it an ideal location for entrepreneurs and companies. Establishing a company in Singapore can open doors to robust economic opportunities, access to international markets, and a stable political landscape.Singapore’s strategic location, competitive tax regime, world-class infrastructure, and transparency in governance make it a top choice for businesses. <br /><b>Entrepreneurs benefit from:</b>
                        </p>
                        <div className="md:flex ml-12 p-4">

                            <div className='w-1/2'>
                                <ul className="list-disc ml-2">
                                    <li>
                                        Low corporate taxes
                                    </li>
                                    <li>
                                        Ease of Doing Business Index leader
                                    </li>
                                    <li>
                                        Access to a highly skilled workforce</li>
                                    <li>Strong financial ecosystem</li>



                                </ul>
                            </div>
                            <div>
                                <ul className="list-disc ml-2">
                                    <li>
                                        Stable Political Climate
                                    </li>
                                    <li>
                                        Startup Grants and Funding:
                                    </li>
                                    <li>
                                        Mentorship and Networking</li>
                                    <li>Regulatory Sandbox</li>



                                </ul>
                            </div>



                        </div>



                        <span className='font-semibold texts-lg p-4' >Types of Business Entities in Singapore:</span>

                        <ul className="list-decimal  ml-12">
                            <li className='font-semibold'>
                                Private Limited Company (Pte Ltd): <br />
                                <p class="font-normal  text-gray-800"> The most common and preferred business structure, suitable for small and large companies alike, with shareholders' liability limited to their shares.</p>
                            </li>
                            <li className='font-semibold'>
                                Sole Proprietorship: <br />
                                <p class="font-normal  text-gray-800"> A simple structure for individuals, though with unlimited liability.</p>
                            </li>
                            <li className='font-semibold'>
                                Limited Liability Partnership (LLP)<br />
                                <p class="font-normal  text-gray-800"> Ideal for professional services, where partners’ liability is limited.</p>
                            </li>
                            <li className='font-semibold'>
                                Branch Office or Subsidiary<br />
                                <p class="font-normal  text-gray-800"> Foreign entities looking to expand to Singapore can set up a branch or a subsidiary, with the latter offering more autonomy and liability protection.</p>
                            </li>

                        </ul>

                    </span>


                </div>





                <div class="md:w-1/2 w-full h-full">


                    <img src="/images/company_vision.jpg" alt="Company Vision"
                        class="md:block object-cover mt-36 shadow-2xl border-4 border-red-500 
                    w-full h-5/6 overflow-hidden rounded-full hidden" />



                </div>
            </div>
            <div class=" h-full w-full ">
                <div class="grid grid-cols-2 p-20 justify-items-center">
                    <span class="">
                        <div class="md:w-48 md:h-24 w-32 h-24 ">
                            <img src="/images/agency/acra.svg" alt="acra" class="md:w-full md:h-fit object-cover  rounded-xl" />
                        </div>
                        <p className="font-normal text-sm text-gray-400"> Registered filing agent FA20170615

                        </p>

                    </span>
                    <span class="">
                        <div class="md:w-48 md:h-24 w-32 h-24">
                            <img src="/images/agency/isca.svg" alt="isca" class="w-full h-fit object-cover rounded-xl" />
                        </div>
                        <p className="font-normal text-sm text-gray-400"> Our accountants are certified & <br /> We are Accredited Training Organisation by ISCA
                        </p>

                    </span>








                </div>

            </div>

            <div class="md:flex w-full h-full">

                <div class="md:w-1/2 w-full h-full md:mt-0 p-4">

                    <figure class="relative  max-w-2xl Z-10   transition-all float-right duration-300 cursor-pointer filter">

                        <img src="/images/knowmore.jpg" alt="Company Vision"
                            class="object-cover w-full md:w-3/4 justify-center overflow-hidden md:mt-10 md:ml-16 rounded-2xl shadow-2xl" />
                        <figcaption class="absolute px-4 text-lg text-white md:top-10 md:left-24 top-2 left-2">
                            <p className='text-2xl font-bold text-center text-white'>Discover Your Next Adventure! </p>
                        </figcaption>
                    </figure>

                </div>

                <div class="md:w-1/2 md:p-4 mt-12 md:m-4 w-full m-4 p-4">
                    <form onSubmit={handleSubmit}>
                        <span className='pt-10 p-4 text-2xl font-google-sans font-extrabold '>Your Journey Starts Here, Discover Your Next Adventure</span>
                        <div class="grid gap-6 mb-6 md:grid-cols-2 mt-4 md:p-4">

                            <div>
                                <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900" >Your  name</label>
                                <input type="text" value={formData.name} name="name"
                                    onChange={handleChange}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Your Name" required />
                            </div>


                            <div>
                                <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 ">Phone number</label>
                                <input type="tel" value={formData.tel} name="tel"
                                    onChange={handleChange}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="123-45-678"  required />
                            </div>
                            <div>
                                <label for="website" class="block mb-2 text-sm font-medium text-gray-900">Email</label>
                                <input type="text" value={formData.email} name="email"
                                    onChange={handleChange}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="john.doe@company.com" required />
                            </div>
                            <div>
                                <label for="country" class="block mb-2 text-sm font-medium text-gray-900">Country of Origin</label>
                                <input type="text" value={formData.country} name="country"
                                    onChange={handleChange}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="United States" required />

                            </div>

                        </div>
                        <div class="mb-6 md:m-4">
                            <div>
                                <label for="comments" class="block mb-2 text-sm font-medium text-gray-900 ">Any comments</label>
                                <textarea value={formData.message} name="message"
                                    onChange={handleChange}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="I need to setup company in Singapore" />
                            </div>
                        </div>

                        <button disabled={!isFormValid.valid} type="submit" class="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Submit</button>


                        {submitStatus === 'success' && (
                            <div className="p-3 bg-green-50 border border-green-200 text-green-600 rounded">
                                Message sent successfully!
                            </div>
                        )}

                        {submitStatus === 'error' && (
                            <div className="p-3 bg-red-50 border border-red-200 text-red-600 rounded">
                                {error || 'Failed to send message. Please try again.'}
                            </div>
                        )}
                    </form>
                </div>



            </div>




        </div >



    );
};

export default Incorporation;