import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react/dist/iconify.js';
import { faRocket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FeatureBox = ({ icon, color, title, description ,link}) => (
    <Link to={link} className="flex-col gap-4 m-4  bg-white p-6 rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-xl border border-gray-100  items-center text-center ">
        <div className="flex justify-center">
        <div className="  h-20 w-20  p-4 bg-blue-50 rounded-full group-hover:bg-blue-100 transition-colors duration-300 ">
            <Icon icon={icon} color={color} className="w-full h-full " />
        </div>
        </div>
       
        <div>
        <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
        <p className="text-gray-600">{description}</p>

        </div>
        
   </Link>
);

const features = [
    {
        icon: 'bxs:business',
        title: "Incorporation Services",
        description: "We help businesses securely and efficiently create a new company",
        color: "green",
        link: "/incorporation"
    },
    {
        icon: 'pajamas:license',
        title: "Accounting Services",
        description: "We help businesses streamline their accounting and financial management",
        color: "purple",
        link: "/accounting"
    },
    {
        icon: 'mingcute:user-security-fill',
        title: "Secretarial Services",
        description: "We help businesses manage their secretarial needs efficiently",
        color: "blue",
        link: "/secretarial"
    },
    {
        icon: 'tabler:tax',
        title: "Taxation Services",
        description: "We help businesses navigate the complex taxation landscape",
        color: "red",
        link: "/tax"
    },
    {
        icon: 'fxemoji:girlgirl',
        title: "About Us",
        description: "Learn more about Zen Corp and our mission",
        color: "red",
        link: "/about"

    },
    {
        icon: 'line-md:phone-call-twotone-loop',
        title: "Contact Us",
        description: "Reach out to us for any questions or inquiries",
        color: "red",
        link: "/contact"


    }
];

export default function MainContent() {
    return (
        <main>
           <div class="md:flex">
                <div class="w-full p-4 md:w-1/2 md:p-4 ">
                    <div class="flex items-center pl-4">
                    
                        <h1 class=" mt-16 max-w-2xl mb-2 text-3xl font-extrabold leading-none tracking-tight mr-2  md:text-5xl  ">Welcome to Zen Corp</h1>
                        <FontAwesomeIcon icon={faRocket} className="mt-16 w-8 h-full justify-end animate-pulse text-green-400" />
                        <FontAwesomeIcon icon={faRocket} className="mt-16 w-8  h-full justify-end animate-pulse text-green-400" /> 
                    </div>
                    <p class="max-w-2xl font-semibold mb-6 p-4 lg:mb-8 md:text-lg lg:text-md ">We make oraganizations and entrepreneurs jumpstart their local business ventures.
                        Yearning for effortless company incorporation and top-notch
                        accounting solutions to enhance your business success.Simplify your business journey with our comprehensive
                        incorporation, accounting, and secretarial services.

                        <ul class="list-disc text-base  font-normal ml-6">
                            <li ><p className="text-base font-semibold">Seamless Company Incorporation:</p> Let us handle the intricate process of setting up your business, from registration to licensing.</li>
                            <li><p className="text-base  font-semibold">Expert Accounting Services:</p> Our skilled accountants provide accurate and timely financial reporting, tax planning, and compliance.</li>
                            <li><p className="text-base  font-semibold">Taxation Support: </p>Efficient tax planning and compliance for local and international businesses.Corporate/Personal/GST/Transfer Pricing</li>
                            <li><p className="text-base  font-semibold">Personalized Service:</p> No Bots! We tailor our solutions to meet your unique business needs with humans to suport.</li>
                            <li><p className="text-base  font-semibold">Efficient and Compliant:</p> Our team ensures compliance with all relevant laws and regulations.</li>
                        </ul>
                        <Link to="/contact" >
                        <button className="block px-4 py-2 text-white bg-red-500 mt-4 ml-6 rounded-xl shadow-md hover:bg-green-600">Get Started Now!</button>
                        </Link>
                    </p>
                </div>
                <div class="hidden md:w-1/2 md:p-4 md:block ">
                        <img src="./images/knowmore.jpg" className='rounded-3xl  md:w-5/6 md:mt-24 ' alt="hero image" />
                </div>
               
            </div>

            <div class="flex justify-center w-full">
                
             
                    <div className="md:w-3/4  md:flex-none w-full">
                        <div className="md:grid md:grid-cols-3 grid grid-cols-1">
                            {features.map((feature, index) => (
                                <FeatureBox
                                    key={index}
                                    icon={feature.icon}
                                    color={feature.color}
                                    title={feature.title}
                                    description={feature.description}
                                    link={feature.link}
                                />
                            ))}
                     
                    </div>
                    </div>
                
            </div> 
            

        </main >


    )
}
