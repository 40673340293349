import React, { useState } from 'react';
import Header from './header';
import Footer from './footer';
import { Icon } from '@iconify/react/dist/iconify.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Loader2 } from 'lucide-react';
import useHttpService from './useHttpService';


function Secraterial() {
    const { isLoading, error, post } = useHttpService();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        tel: '',
        country: ''
    });
    const [isFormValid, setIsFormValid] = useState({ valid: formData.name != '' && formData.email != '' && formData.tel != '' && formData.country != '' });

    const [submitStatus, setSubmitStatus] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setIsFormValid({ valid: formData.name != '' && formData.email != '' && formData.tel != '' && formData.country != '' });
        console.log(isFormValid);
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitStatus('');

        try {
            const response = await post('services.php', {
                action: 'submit_form',
                ...formData
            });

            setSubmitStatus('success');
            setFormData({ name: '', email: '', message: '', tel: '', country: '' }); // Reset form
            console.log('Form submitted successfully:', response);
        } catch (err) {
            setSubmitStatus('error');
            console.error('Form submission failed:', err);
        }
    };
    return (

        <div className="min-h-screen">

            {/* Top message with importance of process*/}
            <div class="md:flex w-full h-full">
                <div class="md:w-1/2 w-full h-full md:mt-16 p-4">
                    <span className="flex" >
                        <Breadcrumbs aria-label="breadcrumb" className="float-start">
                            <Link underline="hover" color="inherit" href="/" className="text-lg font-bold">
                                Home
                            </Link>

                            <Link
                                underline="hover"
                                color="text.primary"

                                aria-current="page"
                            >
                                Secratarial Services
                            </Link>
                        </Breadcrumbs>

                    </span>
                    <div class="flex md:mt-5 mt-2">
                        <span className="md:w-1/12 w-12 mt-4 p-4 mr-2">

                            <Icon icon="hugeicons:legal-document-01" color="red" class="w-12 h-12  " />
                        </span>

                        <p className="flex-grow underline text-2xl font-bold mt-2 p-4  ">
                            Company Secretarial Services in Singapore   </p>



                    </div>


                    <span className="text-lg text-wrap p-4">
                        Operating a successful business in Singapore requires more than just great ideas and diligent execution—it demands strict compliance with regulatory requirements, effective corporate governance, and robust support for smooth business operations. Our Company Secretarial Services are crafted to assist businesses across industries, providing tailored, comprehensive support that ensures adherence to Singapore’s corporate laws and standards. Here, we set ourselves apart through commitment to accuracy, confidentiality, and the expert handling of all regulatory obligations.


                        <p className="font-semibold p-4">Our Comprehensive Range of Company Secretarial Services</p>




                        <ul className="list-disc  ml-12">
                            <li className='font-semibold'>
                                Incorporation and Business Setup <br />
                                <p class="font-normal  text-gray-800"> Establishing a business in Singapore is straightforward with our incorporation services. Our experts ensure your business is set up in compliance with the Accounting and Corporate Regulatory Authority (ACRA) standards and complete all required formalities, including statutory filings and document submissions</p></li>
                            <li className='font-semibold'>
                                Company Secretarial Services <br />
                                <p class="font-normal  text-gray-800"> Our company secretarial services encompass the full spectrum of corporate compliance, ensuring you meet all regulatory requirements efficiently. From appointing a company secretary to managing statutory filings and maintaining official registers, our team handles it all with precision.</p></li>
                            <li className='font-semibold'>
                                Board Meeting Management<br />
                                <p class="font-normal  text-gray-800"> We understand the significance of structured, well-organized board meetings. Our services include planning, scheduling, preparing agendas, taking minutes, and keeping track of decisions and actions. This service ensures that all board matters are professionally recorded and compliant with corporate governance requirements.</p></li>
                            <li className='font-semibold'>
                                Annual Returns and Filings<br />
                                <p class="font-normal  text-gray-800"> Meeting annual filing requirements with ACRA is critical to keeping your business in good standing. Our secretarial team is responsible for preparing, reviewing, and filing annual returns accurately and on time, ensuring compliance with statutory obligations.</p></li>


                        </ul>

                    </span>


                </div>
                <div class="md:w-1/2 md:mt-36 md:block w-full h-full p-4 hidden md:align-middle">
                    <img src="/images/boardroom.jpg" alt="Company Vision"
                        class="object-cover md:rounded-full   md:h-full   shadow-2xl border-4 border-red-500 md:w-full md:block overflow-hidden hidden" />

                </div>

            </div>
            <div class="md:flex w-full h-full">
                <div class="md:w-1/2 md:block w-full h-full md:mt-16  ">
                    <img src="/images/knowmore16.jpg" alt="Company Vision"
                        class="object-cover mt-5 md:h-5/6  shadow-2xl border-4 border-red-500 md:w-full md:block overflow-hidden rounded-full hidden" />

                </div>
                <div class="md:w-1/2 w-full h-full md:mt-16 p-4">
                    <ul className="list-disc  ml-12 text-lg text-wrap">
                        <li className='font-semibold'>
                            Corporate Governance Advisory<br />
                            <p class="font-normal  text-gray-800">Corporate governance is crucial for maintaining investor confidence and fostering sustainable growth. Our advisory services support your organization with insights into corporate governance best practices and regulatory changes, helping you build a resilient governance framework.</p></li>

                        <li className='font-semibold'>

                            Statutory Compliance Monitoring<br />
                            <p class="font-normal  text-gray-800">With frequent regulatory updates, staying compliant can be challenging. Our statutory compliance monitoring services ensure that all changes in corporate law are adhered to, keeping your company on track with ACRA requirements at all times.</p></li>

                        <li className='font-semibold'>
                            Preparation and Submission of Statutory Documents<br />
                            <p class="font-normal  text-gray-800">Our team manages the preparation and timely submission of statutory documents to relevant authorities, reducing administrative burden and minimizing the risk of fines or penalties for non-compliance.</p></li>
                        <li className='font-semibold'>
                            Maintaining Company Records<br />
                            <p class="font-normal  text-gray-800">Proper maintenance of company records is not only a legal requirement but also an essential part of effective corporate governance. We ensure that all statutory records, including share registers, directors' and shareholders' details, are kept up-to-date and are readily accessible when needed.</p></li>

                        <li className='font-semibold'>
                            Shareholder and Stakeholder Communication<br />
                            <p class="font-normal  text-gray-800">Clear communication with shareholders and stakeholders fosters transparency and trust. We assist with the preparation and distribution of shareholder communications, ensuring that updates and essential information are delivered accurately and in compliance with legal requirements. </p></li>
                    </ul>
                </div>

            </div>

            <div class="md:flex w-full h-full">
                <div class="md:w-1/2 w-full h-full md:mt-0 p-4">
                    <figure class="relative mt-10 max-w-2xl Z-10   transition-all float-right duration-300 cursor-pointer filter">

                        <img src="/images/knowmore3.jpg" alt="Company Vision"
                            class="object-cover w-full md:w-3/4 justify-center overflow-hidden md:mt-10 md:ml-16 rounded-2xl shadow-2xl" />
                        <figcaption class="absolute px-4 text-lg text-white md:top-10 md:left-24 top-2 -left-4">
                            <p className='text-2xl font-bold text-center text-white'>Know more about your compliances! </p>
                        </figcaption>
                    </figure>
                </div>
                <div class="md:w-1/2 w-full h-full md:mt-16 p-4 ">
                    <form onSubmit={handleSubmit}>
                        <span className='mt-28 pt-12 text-2xl font-google-sans font-extrabold '>Understand Your Compliances Better, Setup a call!</span>
                        <div class="grid gap-6 mb-6 md:grid-cols-2 mt-4 p-2">

                            <div>
                                <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 ">Your  name</label>
                                <input type="text" value={formData.name} name="name"
                                    onChange={handleChange}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Your Name" required />
                            </div>


                            <div>
                                <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 ">Phone number</label>
                                <input type="tel" value={formData.tel} name="tel"
                                    onChange={handleChange}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="123-45-678"  required />
                            </div>
                            <div>
                                <label for="website" class="block mb-2 text-sm font-medium text-gray-900 ">Email</label>
                                <input type="text" value={formData.email} name="email"
                                    onChange={handleChange}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="john.doe@company.com" required />
                            </div>
                            <div>
                                <label for="country" class="block mb-2 text-sm font-medium text-gray-900 ">Country of Origin</label>
                                <input type="text" value={formData.country} name="country"
                                    onChange={handleChange}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="United States" required />

                            </div>

                        </div>
                        <div class="mb-6">
                            <div>
                                <label for="comments" class="block mb-2 text-sm font-medium text-gray-900">Any comments</label>
                                <textarea value={formData.message} name="message"
                                    onChange={handleChange}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="I need to setup company in Singapore" />
                            </div>
                        </div>

                        <button disabled={!isFormValid.valid} type="submit" class="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center   ">Submit</button>
                        {/* Status Messages */}

                        {submitStatus === 'success' && (
                            <div className="p-3 bg-green-50 border border-green-200 text-green-600 rounded">
                                Message sent successfully!
                            </div>
                        )}

                        {submitStatus === 'error' && (
                            <div className="p-3 bg-red-50 border border-red-200 text-red-600 rounded">
                                {error || 'Failed to send message. Please try again.'}
                            </div>
                        )}

                    </form>
                </div>

            </div>




        </div>








    );
};

export default Secraterial;