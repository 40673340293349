import React from 'react';
import Header from './header';
import Footer from './footer';
import { Icon } from '@iconify/react/dist/iconify.js';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';


function Payroll() {
    return (


        <div class="flex w-full h-full">


            <div class="w-1/2 mt-16 ml-16 font-google-sans font-normal">
                <span className="flex" >
                <Breadcrumbs aria-label="breadcrumb" className="float-start">
                    <Link underline="hover" color="inherit" href="/" className="text-lg font-bold">
                        Home
                    </Link>
                  
                    <Link
                        underline="hover"
                        color="text.primary"
                        
                        aria-current="page"
                    >
                        Incorporation Services
                    </Link>
                </Breadcrumbs>
                    
                </span>
                

                <div class="flex mt-5">
                    <span className="w-1/12">
                        <Icon icon="emojione-v1:flag-for-singapore" class="w-12 h-12" />
                    </span>

                    <p className="flex-grow underline text-2xl font-bold mt-2   dark:text-white">
                        Why Setup Business In Singapore?</p>



                </div>


                <span className="text-lg text-wrap  ">
                    Singapore is globally recognized for its pro-business environment, efficient regulatory framework, and supportive infrastructure, making it an ideal location for entrepreneurs and companies. Establishing a company in Singapore can open doors to robust economic opportunities, access to international markets, and a stable political landscape.Singapore’s strategic location, competitive tax regime, world-class infrastructure, and transparency in governance make it a top choice for businesses. <b>Entrepreneurs benefit from:</b>
                    <div className="flex ml-12 ">

                        <div className='w-1/2'>
                            <ul className="list-disc ml-2">
                                <li>
                                    Low corporate taxes
                                </li>
                                <li>
                                    Ease of Doing Business Index leader
                                </li>
                                <li>
                                    Access to a highly skilled workforce</li>
                                <li>Strong financial ecosystem</li>



                            </ul>
                        </div>
                        <div>
                            <ul className="list-disc ml-2">
                                <li>
                                    Stable Political Climate
                                </li>
                                <li>
                                    Startup Grants and Funding:
                                </li>
                                <li>
                                    Mentorship and Networking</li>
                                <li>Regulatory Sandbox</li>



                            </ul>
                        </div>



                    </div>



                    <span className='font-semibold texts-lg ' >Types of Business Entities in Singapore:</span>

                    <ul className="list-decimal  ml-12">
                        <li className='font-semibold'>
                            Private Limited Company (Pte Ltd): <br />
                            <p class="font-normal  text-gray-800"> The most common and preferred business structure, suitable for small and large companies alike, with shareholders' liability limited to their shares.</p>
                        </li>
                        <li className='font-semibold'>
                            Sole Proprietorship: <br />
                            <p class="font-normal  text-gray-800"> A simple structure for individuals, though with unlimited liability.</p>
                        </li>
                        <li className='font-semibold'>
                            Limited Liability Partnership (LLP)<br />
                            <p class="font-normal  text-gray-800"> Ideal for professional services, where partners’ liability is limited.</p>
                        </li>
                        <li className='font-semibold'>
                            Branch Office or Subsidiary<br />
                            <p class="font-normal  text-gray-800"> Foreign entities looking to expand to Singapore can set up a branch or a subsidiary, with the latter offering more autonomy and liability protection.</p>
                        </li>

                    </ul>

                </span>
                <figure class="relative mt-8 max-w-2xl    transition-all float-right duration-300 cursor-pointer filter  hover:grayscale-0">

                    <img src="/images/knowmore.jpg" alt="Company Vision"
                        class="object-cover w-3/4 justify-center overflow-hidden mt-10 ml-16 rounded-2xl shadow-2xl" />
                    <figcaption class="absolute px-4 text-lg text-white top-10 left-36">
                        <p className='text-2xl font-bold text-center text-white'>Discover Your Next Adventure! </p>
                    </figcaption>
                </figure>

            </div>
            <div class="w-1/2 mt-32  p-4">
                <img src="/images/company_vision.jpg" alt="Company Vision"
                    class="object-cover     shadow-2xl border-4 border-red-500 w-full overflow-hidden rounded-full" />

                <div class="mt-10">
                    <form>
                        <span className='pt-10 text-2xl font-google-sans font-extrabold '>Your Journey Starts Here, Discover Your Next Adventure</span>
                        <div class="grid gap-6 mb-6 md:grid-cols-2 mt-4 p-2">

                            <div>
                                <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your  name</label>
                                <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your Name" required />
                            </div>


                            <div>
                                <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone number</label>
                                <input type="tel" id="phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="123-45-678" pattern="\+[1-9]\d{1,2}[\s-]?\d{10}" required />
                            </div>
                            <div>
                                <label for="website" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                <input type="text" id="website" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="john.doe@company.com" required />
                            </div>
                            <div>
                                <label for="country" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Country of Origin</label>
                                <input type="text" id="country" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="United States" required />

                            </div>

                        </div>
                        <div class="mb-6">
                            <div>
                                <label for="comments" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Any comments</label>
                                <textarea id="comments" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="I need to setup company in Singapore" />
                            </div>
                        </div>

                        <button type="submit" class="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                    </form>
                </div>



            </div>




        </div >



    );
};

export default Payroll;