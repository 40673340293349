// useHttpService.js
import { useState, useCallback } from 'react';

const BASE_URL = '/service/';  // Adjust this based on your server configuration

const useHttpService = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Generic request handler with default options
  const sendRequest = useCallback(async ({
    endpoint = 'services.php',  // Default endpoint
    method = 'GET',
    body = null,
    headers = {},
    formData = null,
    queryParams = null
  }) => {
    setIsLoading(true);
    setError(null);

    try {
      // Construct the URL with query parameters if provided
      let url = `${BASE_URL}${endpoint}`;
      if (queryParams) {
        const params = new URLSearchParams(queryParams);
        url += `?${params.toString()}`;
      }

      // Prepare request options
      const requestOptions = {
        method,
        credentials: 'include', // Includes cookies in the request
        headers: {
          ...headers
        }
      };

      // Handle different types of request bodies
      if (formData) {
        // For FormData, don't set Content-Type header - browser will set it with boundary
        requestOptions.body = formData;
      } else if (body) {
        requestOptions.headers['Content-Type'] = 'application/json';
        requestOptions.body = JSON.stringify(body);
      }

      const response = await fetch(url, requestOptions);

      // Check if response is JSON
      const contentType = response.headers.get('content-type');
      let data;
      
      if (contentType && contentType.includes('application/json')) {
        data = await response.json();
      } else {
        data = await response.text();
      }

      // Check for PHP errors in response
      if (!response.ok) {
        throw new Error(data.message || 'Request failed');
      }

      setIsLoading(false);
      return data;

    } catch (err) {
      setError(err.message || 'Something went wrong!');
      setIsLoading(false);
      throw err;
    }
  }, []);

  // Convenience method for GET requests
  const get = useCallback((endpoint, queryParams = null) => {
    return sendRequest({
      endpoint,
      method: 'GET',
      queryParams
    });
  }, [sendRequest]);

  // Convenience method for POST requests
  const post = useCallback((endpoint, data, isFormData = false) => {
    return sendRequest({
      endpoint,
      method: 'POST',
      ...(isFormData ? { formData: data } : { body: data })
    });
  }, [sendRequest]);

  // Convenience method for PUT requests
  const put = useCallback((endpoint, data) => {
    return sendRequest({
      endpoint,
      method: 'PUT',
      body: data
    });
  }, [sendRequest]);

  // Convenience method for DELETE requests
  const delete_ = useCallback((endpoint) => {
    return sendRequest({
      endpoint,
      method: 'DELETE'
    });
  }, [sendRequest]);

  return {
    isLoading,
    error,
    sendRequest,
    get,
    post,
    put,
    delete: delete_
  };
};

export default useHttpService;