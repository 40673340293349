import React, { useState } from 'react';
import Header from './header';
import Footer from './footer';
import { Icon } from '@iconify/react/dist/iconify.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Loader2 } from 'lucide-react';
import useHttpService from './useHttpService';
import FontAwesome from 'react-fontawesome';

function Accounting() {
    const { isLoading, error, post } = useHttpService();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        tel: '',
        country: ''
    });
    const [isFormValid, setIsFormValid] = useState({ valid: formData.name != '' && formData.email != '' && formData.tel != '' && formData.country != '' });

    const [submitStatus, setSubmitStatus] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setIsFormValid({ valid: formData.name != '' && formData.email != '' && formData.tel != '' && formData.country != '' });
        console.log(isFormValid);
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitStatus('');

        try {
            const response = await post('services.php', {
                action: 'submit_form',
                ...formData
            });

            setSubmitStatus('success');
            setFormData({ name: '', email: '', message: '', tel: '', country: '' }); // Reset form
            console.log('Form submitted successfully:', response);
        } catch (err) {
            setSubmitStatus('error');
            console.error('Form submission failed:', err);
        }
    };
    return (


        <div className="min-h-screen">

            {/* Top message with importance of process*/}
            <div class="md:flex w-full h-full">
                <div class="md:w-1/2 w-full h-full md:mt-16 px-4">

                <span className="flex" >
                        <Breadcrumbs aria-label="breadcrumb" className="float-start">
                            <Link underline="hover" color="inherit" href="/" className="text-lg font-bold">
                                Home
                            </Link>

                            <Link
                                underline="hover"
                                color="text.primary"

                                aria-current="page"
                            >
                                Accounting Services
                            </Link>
                        </Breadcrumbs>

                    </span>


                    <div class="flex md:mt-5 mt-8">
                    <span className="md:w-1/12 w-12 mt-6 p-4 mr-4">
                            <Icon icon="material-symbols:finance-mode-rounded" color="green" class="w-12 h-12" />
                        </span>

                        <p className="flex-grow underline text-2xl font-bold mt-2 ml-2 mb-2">
                            Driving Growth with Professional CFO & Accounting Support in Singapore</p>



                    </div>


                    <span className="text-lg text-wrap">
                        <p class="px-4 mx-4">At Zen Corp, we empower Singapore businesses to thrive with robust, comprehensive CFO and accounting solutions tailored to meet local regulatory and financial demands. Whether you’re a startup, growing SME, or an established corporation, our team provides strategic insights and financial clarity essential for sustainable growth and success. With our full suite of services, from daily accounting tasks to high-level financial strategy, we are dedicated to elevating your business and simplifying complex financial landscapes.</p>
                        
                        <br />  <span className="text-lg font-bold p-4">Why Choose Us?</span>

                        <ul className="list-disc  ml-12">
                            <li className='font-semibold'>
                                Expertise in Singapore’s Regulatory Landscape <br />
                                <p class="font-normal  text-gray-800"> Navigating Singapore’s intricate financial regulations is critical to sustainable success. Our team of seasoned professionals possesses a deep understanding of local compliance requirements and financial best practices, ensuring your business remains compliant with ACRA, IRAS, and other regulatory bodies. We stay ahead of regulatory updates, so you can focus on your business’s growth and innovation with peace of mind.</p>
                            </li>
                            <li className='font-semibold'>
                                Comprehensive Financial Management<br />
                                <p class="font-normal  text-gray-800"> Our CFO services are designed to go beyond routine accounting tasks, acting as your strategic financial partner. From customized reporting to detailed forecasting, budgeting, and performance analysis, our services empower you to make data-driven decisions with confidence. With our support, you gain a clear financial roadmap and actionable insights to steer your business toward growth and stability.</p>
                            </li>
                            <li className='font-semibold'>
                                End-to-End Accounting Services<br />
                                <p class="font-normal  text-gray-800"> Whether it’s bookkeeping, payroll, or tax planning, our accounting solutions cover every aspect of financial management. Leveraging advanced accounting software, we streamline processes for both accuracy and efficiency, giving you real-time access to your financial health. Our proactive approach ensures your finances are always in order, freeing you from administrative burdens so you can focus on scaling your business.</p>
                            </li>


                        </ul>
                    </span>
                </div>
                <div class="md:w-1/2 md:block  w-full h-full md:mt-32 p-4 hidden">
                    <img src="/images/officemain3.jpg" alt="Company Vision"
                        class="object-cover  mt-24 shadow-2xl border-1 w-full h-full overflow-hidden rounded-3xl" />

                </div>
            </div>


            <div class=" md:flex h-full w-full ">
                <div class="md:w-1/2  md:block w-full hidden">
                    <div class="flex justify-center m-4">
                        <img src="/images/ipadwithnumbers.jpeg" alt="Company Vision"
                            class="object-cover mt-2 w-3/4      shadow-2xl border-4  overflow-hidden rounded-xl" />
                    </div>

                </div>

                <div class="md:w-1/2 w-full h-full  p-4">
                    <div className="mt-0 ">
                        <h1 class="text-slate-900 font-extrabold text-4xl sm:text-5xl lg:text-6xl tracking-tight ">
                            Precision at Your Fingertips
                        </h1>
                        <p className='p-2'>Our team of experts will provide you with a clear understanding of your cash flow. We'll analyze your financial data and deliver personalized insights to help you make informed decisions.
                            We'll provide a detailed analysis of your cash flow, identifying areas for improvement and opportunities for growth.
                        </p>
                        <ul className="list-disc text-left ml-10">
                            <li>

                                <b>Dedicated Account Management:</b> A dedicated financial advisor will work closely with you to understand your specific needs and goals.
                            </li>
                            <li>

                                <b>Customized Financial Analysis:</b> We’ll analyze your financial data to provide tailored insights and recommendations
                            </li>
                            <li>

                                <b>Proactive Financial Guidance:</b> Our team will proactively identify potential financial risks and opportunities and provide timely advice.
                            </li>
                        </ul>


                    </div>

                </div>
            </div>
            <div class="md:flex  h-full w-full ">
            <div class="md:w-1/2 w-full h-full p-4">
                <h1 class="text-slate-900 font-extrabold text-4xl ml-8 sm:text-5xl lg:text-6xl tracking-tight ">
                          Our Accounting<br/> Services
                        </h1>

                    <ul className="list-disc  ml-12 text-lg text-wrap">
                        <li className='font-semibold'>
                            Virtual CFO Services<br />
                            <p class="font-normal  text-gray-800"> Our virtual CFO services provide the strategic expertise of a high-level financial officer without the cost of a full-time role. Our dedicated CFOs bring a wealth of experience across various industries, providing customized strategic planning, cash flow management, and financial analysis. We simplify complex financial data, helping you make informed decisions that align with your goals.</p>
                        </li>
                        <li className='font-semibold'>
                            Bookkeeping & Compliance<br />
                            <p class="font-normal  text-gray-800"> Accurate bookkeeping is the cornerstone of financial health and compliance. We meticulously manage your bookkeeping to ensure your records are accurate and up-to-date, while keeping your business compliant with statutory requirements from IRAS, CPF, and ACRA. Our efficient, thorough approach protects you from potential regulatory penalties and maintains your financial integrity.</p>
                        </li>
                        <li className='font-semibold'>
                            Tax Planning & Filing<br />
                            <p class="font-normal  text-gray-800"> Taxation can be complex and demanding, especially with frequent regulatory updates. Our tax experts specialize in creating tax strategies that maximize benefits and minimize liabilities, ensuring full compliance with local tax laws. From proactive planning to timely filing, we optimize your tax position to safeguard and enhance your business’s profitability.</p>
                        </li>
                        <li className='font-semibold'>
                            Payroll Processing<br />
                            <p class="font-normal  text-gray-800"> Managing payroll can be time-intensive and requires strict adherence to MOM regulations. Our payroll solutions offer peace of mind with accurate and timely salary calculations, CPF submissions, and employee statements, reducing administrative workload and allowing you to focus on growth.</p>
                        </li>
                    </ul>

                </div>
                <div class="md:w-1/2 md:block w-full hidden ">
               
                <div class="flex justify-center m-4">
                    <img src="/images/laptopwithnumbers.jpeg" alt="Company Vision"
                        class="object-cover mt-36 w-3/4       shadow-2xl border-4  overflow-hidden rounded-xl" />


                </div>
                </div>
                
            </div>

            <div class="md:flex h-full w-full ">
                <div class="md:w-1/2 w-full h-full md:mt-16 p-4">
                    <figure class="relative  max-w-2xl    transition-all float-right duration-300 cursor-pointer filter  hover:grayscale-0">

                        <img src="/images/knowmore4.jpg" alt="Company Vision"
                            class="object-cover w-3/4 justify-center overflow-hidden mt-10 ml-16 rounded-2xl shadow-2xl" />
                        <figcaption class="absolute px-4 text-lg text-black top-5 md:left-36 md:mt-8 mt-6 left-16">
                            <p className='text-lg font-bold text-center text-white'>Streamline CFO & Accounting  </p>
                        </figcaption>
                    </figure>
                </div>
                <div class="md:w-1/2 w-full h-full md:mt-16 p-4 m-4 ">
                    <span className='pt-10 text-2xl mt-4 font-google-sans font-extrabold '>Why Outsource Your CFO & Accounting Needs?</span>
                    <span className="text-lg text-wrap  ">
                        <br />Outsourcing CFO and accounting functions allows you to access high-level financial expertise and streamlined operations without the expense of a full-time team. Whether you need interim support or a long-term financial partner, our services are designed to scale with your needs. Partner with Zen Corp to unlock your business's full potential, supported by financial professionals dedicated to your success.
                        <br /><b>Reach out to us to understand our services and make an informed decision.</b></span>
                    <form onSubmit={handleSubmit}>
                        <div class="grid gap-6 mb-6 md:grid-cols-2 mt-4 p-2 m-4">

                            <div>
                                <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900" >Your  name</label>
                                <input type="text" value={formData.name} name="name"
                                    onChange={handleChange}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Your Name" required />
                            </div>


                            <div>
                                <label for="phone" class="block mb-2 text-sm font-medium text-gray-900">Phone number</label>
                                <input type="tel" value={formData.tel} name="tel"
                                    onChange={handleChange}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="123-45-678"  required />
                            </div>
                            <div>
                                <label for="website" class="block mb-2 text-sm font-medium text-gray-900 ">Email</label>
                                <input type="text" value={formData.email} name="email"
                                    onChange={handleChange}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="john.doe@company.com" required />
                            </div>
                            <div>
                                <label for="country" class="block mb-2 text-sm font-medium text-gray-900">Country of Origin</label>
                                <input type="text" value={formData.country} name="country"
                                    onChange={handleChange}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="United States" required />

                            </div>

                        </div>
                        <div class="mb-6 m-4">
                            <div>
                                <label for="comments" class="block mb-2 text-sm font-medium text-gray-900 ">Any comments</label>
                                <textarea value={formData.message} name="message"
                                    onChange={handleChange}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="I need to setup company in Singapore" />
                            </div>
                        </div>
                        <div class="mb-6">
                        <button disabled={!isFormValid.valid} type="submit" class="text-white  m-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center">Submit</button>
                        </div>
                        {/* Status Messages */}

                        {submitStatus === 'success' && (
                            <div className="p-3 bg-green-50 border border-green-200 text-green-600 rounded">
                                Message sent successfully!
                            </div>
                        )}

                        {submitStatus === 'error' && (
                            <div className="p-3 bg-red-50 border border-red-200 text-red-600 rounded">
                                {error || 'Failed to send message. Please try again.'}
                            </div>
                        )}
                    </form>
                </div>

            </div >

        </div>


    );
};

export default Accounting;