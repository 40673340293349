import React, { useState } from 'react';
import Header from './header';
import Footer from './footer';
import { Icon } from '@iconify/react/dist/iconify.js';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Loader2 } from 'lucide-react';
import useHttpService from './useHttpService';

function Contact() {

    const { isLoading, error, post } = useHttpService();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        tel: ''
    });
    const isFormValid = formData.name != '' && formData.email != '' && formData.tel != '';

    const [submitStatus, setSubmitStatus] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(e.target);
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitStatus('');

        try {
            const response = await post('services.php', {
                action: 'submit_form',
                ...formData
            });

            setSubmitStatus('success');
            setFormData({ name: '', email: '', message: '', tel: '' }); // Reset form
            console.log('Form submitted successfully:', response);
        } catch (err) {
            setSubmitStatus('error');
            console.error('Form submission failed:', err);
        }
    };

    return (


        <div className="min-h-screen">

            {/* Top message with importance of process*/}
            <div class="md:flex w-full h-full">
                <div class="md:w-1/2 w-full h-full md:mt-16  p-4 md:flex md:justify-end ">
                    <img src="./images/knowmore6.jpg" alt="About Us" 
                    className=" md:w-3/4 w-full mt-16 h-auto object-cover rounded-xl" />
                </div>
                <div class="md:w-1/2 w-full h-full md:mt-32 p-4">
                    <h1 className="text-5xl font-bold">Let’s Connect</h1>
                    <p className="mt-4 text-2xl p-6">We're eager to discuss your specific needs and explore how our services can benefit your business. Whether you're a startup or an established enterprise, our team of experts is ready to provide tailored solutions.

                        Feel free to reach out to us through the contact form or schedule a consultation. We look forward to hearing from you!</p>
                </div>
            </div>
            <div class="md:flex md:w-full h-full ">
                <div class="md:w-1/2 w-full h-full md:mt-16 p-4 m-4 md:mx-12">
                    <div>
                        <h2 className="text-3xl font-bold mb-4">Contact Form</h2>
                        <form className="space-y-4" onSubmit={handleSubmit}>
                            <input type="text" placeholder="Name" value={formData.name} name="name"
                                onChange={handleChange} className="w-full p-3 border border-gray-300 rounded-lg" />
                            <input type="email" placeholder="Email" value={formData.email} name="email"
                                onChange={handleChange} className="w-full p-3 border border-gray-300 rounded-lg" />
                            <input type="tel" placeholder="Phone Number" value={formData.tel} name='tel'
                                onChange={handleChange} className="w-full p-3 border border-gray-300 rounded-lg" />
                            <textarea placeholder="Message" name="message"
                                value={formData.message}
                                onChange={handleChange} className="w-full p-3 border border-gray-300 rounded-lg" rows="4"></textarea>
                            <button disabled={!isFormValid} type="submit" class="text-white bg-blue-700 hover:bg-blue-800  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center0">Submit</button>
                            {/* Status Messages */}
                            {submitStatus === 'success' && (
                                <div className="p-3 bg-green-50 border border-green-200 text-green-600 rounded">
                                    Message sent successfully!
                                </div>
                            )}

                            {submitStatus === 'error' && (
                                <div className="p-3 bg-red-50 border border-red-200 text-red-600 rounded">
                                    {error || 'Failed to send message. Please try again.'}
                                </div>
                            )}
                        </form>
                    </div>
                </div>
                <div class="md:w-1/2 w-full h-full md:mt-16 p-4 m-4 md:mx-12">
                    <div>
                        <h2 className="text-3xl font-bold mb-4">Contact Details</h2>
                        <p className="text-lg text-gray-700 mb-4">Feel free to reach out to us by phone, email, or visit us at our office.</p>
                        <p className="font-semibold text-blue-900">Address:</p>
                        <p className="text-gray-700 mb-4">10, Anson Rd,
                            #29-03A, International Plaza
                            Singapore 079903</p>
                        <p className="font-semibold text-blue-900">Phone:</p>
                        <p className="text-gray-700">+65 90103503 <a aria-label="Chat on WhatsApp" class="" href="https://wa.me/6590103503"> <img alt="Chat on WhatsApp" src="./images/WhatsAppButtonGreenLarge.svg" />
                        </a></p>

                        <p className="font-semibold text-blue-900">Email:</p>
                        <p className="text-gray-700 mb-4">rama@zencorpsg.com</p>
                        <p className="font-semibold text-blue-900">Business Hours:</p>
                        <p className="text-gray-700">Monday to Friday: 9:00 AM - 6:00 PM</p>
                    </div>
                </div>
            </div>



        </div>




    );
};

export default Contact;